import { Typography,TextField } from "@mui/material";

import React,{useState,useEffect,useRef, useLayoutEffect} from "react";
import "./inventoryDetails.css"
import {Link,useParams} from 'react-router-dom'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Player } from 'video-react';
import emailjs from '@emailjs/browser';
import Reaptcha from 'reaptcha';
// import '~video-react/styles/scss/video-react';
import ReactPlayer from 'react-player'
import axios from 'axios';
import { faArrowLeft, faArrowsTurnRight, faArrowTurnRight, faArrowTurnUp, faCircleInfo, faCirclePlay, faClipboardList, faMultiply, faTurnDown, faTurnUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CurrencyFormat from "react-currency-format";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
  
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }
  
const Details = () => {

const {id1,id} = useParams()

    const [array, setArray] = useState([]);
    const [carouselArray,setCarouselArray] = useState()

    const [verified,setVerified] = useState(false)
    const [csv,setCsv] =  useState([])
    const [name,setName] = useState()
    const [email,setEmail] = useState()
    const [phone,setPhone] = useState()
    const [message,setMessage] = useState()
     const [open, setOpen] = React.useState(false);
     const [caroopen, setcaroOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const caroselOpen = () => setcaroOpen(true);
    const handleClose = () => setOpen(false);
    const carouselClose = () => setcaroOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        overflow:'scroll',
        height:'100%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
     
      };

    function CSVToArray(csvData, delimiter) {
        delimiter = (delimiter || ",");
         var pattern = new RegExp((
        "(\\" + delimiter + "|\\r?\\n|\\r|^)" +
        "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
        "([^\"\\" + delimiter + "\\r\\n]*))"), "gi");
        var data = [[]];
        var matches = null;
        while (matches = pattern.exec(csvData)) {
            var matchedDelimiter = matches[1];
            if (matchedDelimiter.length && (matchedDelimiter != delimiter)) {
                data.push([]);
            }
            if (matches[2]) {
                var matchedDelimiter = matches[2].replace(
                new RegExp("\"\"", "g"), "\"");
            } else {
                var matchedDelimiter = matches[3];
            }
            data[data.length - 1].push(matchedDelimiter);
        }
       
       setCsv(data.splice(1))
    }

    const form = useRef();
    function CSVToJSON(csvData) {

        var data = CSVToArray(csvData);
        var objData = [];
        for (var i = 1; i < data.length; i++) {
            objData[i - 1] = {};
            for (var k = 0; k < data[0].length && k < data[i].length; k++) {
                var key = data[0][k];
                objData[i - 1][key] = data[i][k]
            }
        }
        var jsonData = JSON.stringify(objData);
        jsonData = jsonData.replace(/},/g, "},\r\n");
        return jsonData;
    }


  



            const downloadCsv =  () => {
              fetch(`https://connect.emgsrv.com/${id1}/${id1}.CSV`,{
                    
                    method:'get',
                    headers:{
                        "Content-Type":"application/csv"
                    }
                })
                .then(response => 
                   response.text())
                 
                .then(data => {
                    CSVToJSON(data)
                })
            }
       


            useEffect(() => {
                
                downloadCsv()
               

                


                
 



},[])
const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    overflow:'scroll',
    height:'100%',
    bgcolor: 'background.paper',
   
    p: 1,
  };

const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ajmpcbw', 'template_7tgn2s4',form.current , '4s0sSxigPZvyRqp1P')
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });
};

const onVerify = recaptchaResponse => {
    setVerified(true)
  };

const newdata = csv.filter((datas => datas[19] == id))
console.log(newdata)
const imageCarousel = csv.filter((datas => datas[19] == id)).map(images => images[27])
 
const imagesCarousel = imageCarousel[0]?.split(',').map( function(images) 
{
    return {"image":images}
}
)
const imagesPhoneCarousel = imageCarousel[0]?.split(',').map( function(images) 
{
    return {"image":images}
}
)


const carouselOpenDetails = (carouselId) => {

    setcaroOpen(true)

   
   }
 
   const [width, height] = useWindowSize();
    return (
        <>
        
          {newdata.map(data => (
       <div className="inventory_details_container">

<Modal
        open={open}
        onClose={handleClose}
       
      >
        <Box sx={style}>
        <div onClick={handleClose} className="cancel_return">
                <img height="20" width="20" src="/emgConnect_Return_White.svg" />
                <Typography ml={2} sx={{fontWeight:600,color:"#fff",fontFamily:'latobold',fontSize:'20px'}}>Cancel and Return</Typography>
            </div>
        <div className="request-info-container">
        
             {newdata.map(data => (
               <>
    <Typography sx={{mt:2, mb:2,fontWeight:600,fontFamily:'latobold',fontSize:'18px'}}>Request more information</Typography>

{data[27] ?
        <div className="card_list_image">
        {data[27].split(',')[0] ?
                            <img width="100" src={
                                 data[27].split(',')[0]
                             } />
                             :
                             <img src="/PhotosComingSoon.jpg" />
}
    </div>
    :
    null
}

    <Typography sx={{mt:2, fontWeight:600,fontFamily:'latobold',fontSize:'18px'}}>{data[10]} {data[11]} {data[12]} {data[13]}</Typography>
    <form className="form"  ref={form} onSubmit={sendEmail}>
  
    <TextField 
          id="outlined-multiline-flexible"
          label="Name"
          fullWidth="true"
          multiline
          sx={{mt:2}}
          maxRows={4}
          name="name"
       
         
         
        />
          
    <TextField 
          id="outlined-multiline-flexible"
          label="Email"
          multiline
          sx={{mt:2}}
          fullWidth="true"

          name="email"
          maxRows={4}
         
         
          
        />  
        <TextField 
              id="outlined-multiline-flexible"
              label="Phone"
              name="phone"
              multiline
          fullWidth="true"

              sx={{mt:2}}
              maxRows={4}
             
          

              
            />  
            <TextField 
                                id="outlined-multiline-flexible"

                  label="Type message here"
                  multiline
          fullWidth="true"

                  name="message"
                  sx={{mt:2}}
                  minRows={4}
                
                  
                />
            <TextField
            type="hidden"
              id="outlined-multiline-flexible"
              label="Send to"
              name="send"
              multiline
          fullWidth="true"

              sx={{mt:2,display:'none'}}
              maxRows={4}
              defaultValue={data[7]}

            />
     <div style={{marginTop:'10px'}}>
            <Reaptcha  sitekey="6LcoFfseAAAAAGFB7-b1vK5VNScP9-mPD5nQbLzl" onVerify={onVerify} />
 </div>
 {verified == false ?
   <div  className="button_submit_dis">
      
   <button   type="submit" disabled>Submit</button>
   </div>
   :
   <div className="button_submit">
      
   <button   type="submit" >Submit</button>
   </div>
 }
   </form>
   </>
   ))}
  
    </div>
        </Box>
   
      </Modal>

   

            <div  className="bottom_button">
              
              <Link className="link" to={`/${id1}`}>
              <div className="view_details">
              <img height="20" width="20" src="/emgConnect_Return.svg"  />
                  <Typography className="request-info-text" sx={{ml:0.8,fontWeight:600,fontSize:'14px',display:'flex',alignItems:'center',justifyContent:'center'}} >All Listings</Typography>
              </div>
              </Link>

             
              <div onClick={handleOpen} className="view_details">
              <FontAwesomeIcon size='lg' icon={faCircleInfo} />
              <Typography sx={{ml:0.8, fontWeight:600,fontSize:'14px',display:'flex',alignItems:'center',justifyContent:'center'}} >Request Info</Typography>
              </div>
              

          </div>

                <Typography sx={{mt:2,fontWeight:600,fontFamily:'latobold',fontSize:'18px'}}>{data[10]} {data[11]} {data[12]} {data[13]}</Typography>
           <div className="inventory_details">
               <div className="inventory_image_details">

        
                        
<Carousel swipeable={true} dynamicHeight>
{data[27].split(',').map(images => (
                      <div>
                        {data[27] ?
                    <img src={images} />
                    :
                      <img src="/PhotosComingSoon.jpg" />
                        }
                    </div>
              ))}
              
              {/* <div>
           
              <Player>
      <source src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" />
    </Player>
              </div> */}
              <div>
              <ReactPlayer url={data[31]} />
              </div>
            
</Carousel>


                 
   
                    <div className="inventory_description">
                    <Typography sx={{fontWeight:600,fontFamily:'latobold',fontSize:'24px'}}>Description</Typography>
                    <Typography sx={{fontFamily:'latolight',fontWeight:600}}>
                   
                      {data[28]}

                    </Typography>
                    </div>
                    <div className="additional_information">
                        <Typography className="additional_heading" sx={{fontWeight:600,fontFamily:'latobold',fontSize:'24px'}}>Additional Information</Typography>
                        <Typography mt={1}> {data[23]}</Typography>

                    </div>
               </div>
               <div className="inventory_specification_details">
                   <Typography sx={{fontWeight:600,fontSize:'35px',fontFamily:'latobold'}}>
                   <CurrencyFormat value={data[24]} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                   </Typography>
                <div className="inventory_specification_box">
             
                <div className="inventory_specification_list">
                <Typography sx={{mb:2,fontWeight:600,fontFamily:'latobold',fontSize:'24px',color:'#fff'}}>Details</Typography>

                   <div className="inventory_background"></div>
                   {data[19]  ?
                    <div className="inventory_details_head">
                        <Typography className="inventory_details_header" sx={{ fontWeight:600,fontFamily:'latobold',fontSize:'17px',color:"#fff"}}>VIN:</Typography>
                        <Typography className="inventory_details_subheader" sx={{fontWeight:600,fontFamily:'latolight',fontSize:'16px',color:"#fff"}}>{data[19]}</Typography>
                       

                    </div>
                    :
                    null
                 
            }
                    {data[20] ?
                    <div className="inventory_details_head">
                        <Typography className="inventory_details_header" sx={{ fontWeight:600,fontFamily:'latobold',fontSize:'17px',color:"#fff"}}>MILEAGE:</Typography>
                        <Typography className="inventory_details_subheader" sx={{fontWeight:600,fontFamily:'latolight',fontSize:'16px',color:"#fff"}}> {data[20]} lmph</Typography>
                        

                    </div>
                    :
                    null
                    }
                    {data[16] ?
                    <div className="inventory_details_head">
                        <Typography className="inventory_details_header" sx={{ fontWeight:600,fontFamily:'latobold',fontSize:'17px',color:"#fff"}}>ENGINE:</Typography>
                        <Typography className="inventory_details_subheader" sx={{fontWeight:600,fontFamily:'latolight',fontSize:'16px',color:"#fff"}}>{data[16] }</Typography>
                        

                    </div>
                    :
                    null
                    }
                    {data[17] ?
                    <div className="inventory_details_head">
                        <Typography className="inventory_details_header" sx={{ fontWeight:600,fontFamily:'latobold',fontSize:'17px',color:"#fff"}}>Drive Train:</Typography>
                        <Typography className="inventory_details_subheader" sx={{fontWeight:600,fontFamily:'latolight',fontSize:'16px',color:"#fff"}}>{data[17] }</Typography>
                        

                    </div>
                    :
                    null

}
                    {data[18] ?
                    <div className="inventory_details_head">
                        <Typography className="inventory_details_header" sx={{ fontWeight:600,fontFamily:'latobold',fontSize:'17px',color:"#fff"}}>Fuel Type:</Typography>
                       
                            
                            {data[18] == 'G' ?

                            <Typography className="inventory_details_subheader" sx={{fontWeight:600,fontFamily:'latolight',fontSize:'16px',color:"#fff"}}>
                                    Gasoline Fuel
                            </Typography>
                            :
                            data[18] == 'D' ?
                            <Typography className="inventory_details_subheader" sx={{fontWeight:600,fontFamily:'latolight',fontSize:'16px',color:"#fff"}}>
                            Diesel Fuel
                            </Typography>
                            :
                            data[18] == 'D' ?
                            <Typography className="inventory_details_subheader" sx={{fontWeight:600,fontFamily:'latolight',fontSize:'16px',color:"#fff"}}>
                            Hybrid
                           </Typography>
                             :
                           data[18] == 'E' ?
                           <Typography className="inventory_details_subheader" sx={{fontWeight:600,fontFamily:'latolight',fontSize:'16px',color:"#fff"}}>
                            Electric
                           </Typography>
                             :
      
                           <Typography className="inventory_details_subheader" sx={{fontWeight:600,fontFamily:'latolight',fontSize:'16px',color:"#fff"}}>
                          Other
                           </Typography>

                            }
                        

                    </div>
                    :
                    null
                    }
                    {data[21] ?
                       <div className="inventory_details_head">
                        <Typography className="inventory_details_header" sx={{ fontWeight:600,fontFamily:'latobold',fontSize:'17px',color:"#fff"}}>Color </Typography>
                        <Typography className="inventory_details_subheader" sx={{fontWeight:600,fontFamily:'latolight',fontSize:'16px',color:"#fff"}}>{data[21] }</Typography>
                        

                    </div>
                    :
                    null
}
                </div>
                </div>
                <div style={{marginTop:'50px'}} className="carflix">
                <a target="_blank" href={`https://www.carfax.com/VehicleHistory/p/Report.cfx?partner=DVW_1&vin=${data[19]}`}><img src='/carfax.png' width='155' height='56' border='0' /></a>
                </div>


               </div>
               
           </div>
       </div>
          ))}


       <div className="mobile_screen_display">

               {newdata.map(data =>  (
           <div className="mobile_screen_container">
               <Modal
        open={open}
        onClose={handleClose}
       
      >
        <Box sx={style}>
        <div onClick={handleClose} className="cancel_return">
        <img height="20" width="20" src="/emgConnect_Return_White.svg" />
                <Typography ml={2} sx={{fontWeight:600,color:"#fff",fontFamily:'latobold',fontSize:'20px'}}>Cancel and Return</Typography>
            </div>
        <div className="request-info-container">
       
             {newdata.map(data => (
               <>
    <Typography sx={{mt:2, mb:2,fontWeight:600,fontFamily:'latobold',fontSize:'18px'}}>Request more information</Typography>

    {data[27] ?
                            <img  src={
                                 data[27].split(',')[0]
                             } />
                             :
                             <img src="/PhotosComingSoon.jpg" />
}
    <Typography sx={{mt:2, fontWeight:600,fontFamily:'latobold',fontSize:'18px'}}>{data[10]} {data[11]} {data[12]} {data[13]}</Typography>
    <form className="form"  ref={form} onSubmit={sendEmail}>
  
    <TextField 
          id="outlined-multiline-flexible"
          label="Name"
          fullWidth="true"
          multiline
          sx={{mt:2}}
          maxRows={4}
          name="name"
       
         
         
        />
          
    <TextField 
          id="outlined-multiline-flexible"
          label="Email"
          multiline
          sx={{mt:2}}
          fullWidth="true"

          name="email"
          maxRows={4}
         
         
          
        />  
        <TextField 
              id="outlined-multiline-flexible"
              label="Phone"
              name="phone"
              multiline
          fullWidth="true"

              sx={{mt:2}}
              maxRows={4}
             
          

              
            />  
            <TextField 
                                id="outlined-multiline-flexible"

                  label="Type message here"
                  multiline
          fullWidth="true"

                  name="message"
                  sx={{mt:2}}
                  minRows={4}
                
                  
                />
            <TextField
            disabled
              id="outlined-multiline-flexible"
              label="Send to"
              name="send"
              multiline
          fullWidth="true"
             type="hidden"
              sx={{mt:2,display:'none'}}
              maxRows={4}
              defaultValue={data[7]}


            />
     <div style={{marginTop:'10px',width:'100%'}}>
            <Reaptcha  sitekey="6LcoFfseAAAAAGFB7-b1vK5VNScP9-mPD5nQbLzl" onVerify={onVerify} />
 </div>
 {verified == false ?
   <div  className="button_submit_dis">
      
   <button   type="submit" disabled>Submit</button>
   </div>
   :
   <div style={{background:'red !important'}} className="button_submit">
      
   <button  style={{background:'red !important'}} type="submit" disabled={!verified}>Submit</button>
   </div>
 }
   </form>
   </>
   ))}
  
    </div>
        </Box>
      </Modal>
      <div  className="bottom_button">
              
      <Link className="link" to={`/${id1}`}>
              <div className="view_details">
              <img height="20" width="20" src="/emgConnect_Return.svg"  />
                  <Typography className="request-info-text" sx={{ml:0.8,mt:0.1,fontWeight:600,fontSize:'14px',display:'flex',alignItems:'center',justifyContent:'center'}} >All Listings</Typography>
              </div>
              </Link>

             
              <div onClick={handleOpen} className="view_details">
                  <img height="20" width="20" src="/emgConnect_Info.svg"  />
              <Typography sx={{ml:0.5,mt:0.1, fontWeight:600,fontSize:'14px',display:'flex',alignItems:'center',justifyContent:'center'}} >Request Info</Typography>
              </div>
              

          </div>
           <Typography sx={{fontWeight:600,fontSize:'35px',fontFamily:'latobold'}}>
           <CurrencyFormat value={data[24]} displayType={'text'} thousandSeparator={true} prefix={'$'} />
           </Typography>
           <Typography sx={{fontWeight:600,fontFamily:'latobold',fontSize:'18px'}}>{data[10]} {data[11]} {data[12]} {data[13]}</Typography>
           <div  className="inventory_image_main">
         
           <Carousel
data={imagesPhoneCarousel}
time={2000}
width="850px"
height="500px"

radius="10px"
slideNumber={true}
// slideNumberStyle={slideNumberStyle}
captionPosition="bottom"
automatic={true}
dots={true}
pauseIconColor="white"
pauseIconSize="40px"
slideBackgroundColor="darkgrey"
slideImageFit="cover"
thumbnails={true}
thumbnailWidth="100px"
style={{
  textAlign: "center",
  maxWidth: "850px",
  maxHeight: "100%",
  margin: "40px auto",
}}
/>

                    </div>
                   
                     <div className="inventory_specification_details">
                 
                <div className="inventory_specification_box">
             
                <div className="inventory_specification_list">
                <Typography sx={{mb:2,fontWeight:600,fontFamily:'latobold',fontSize:'24px',color:'#fff'}}>Details</Typography>

                   <div className="inventory_background"></div>
                   {data[19]  ?
                    <div className="inventory_details_head">
                        <Typography className="inventory_details_header" sx={{ fontWeight:600,fontFamily:'latobold',fontSize:'17px',color:"#fff"}}>VIN:</Typography>
                        <Typography className="inventory_details_subheader" sx={{fontWeight:600,fontFamily:'latolight',fontSize:'16px',color:"#fff"}}>{data[19]}</Typography>
                       

                    </div>
                    :
                    null
                 
            }
                    {data[20] ?
                    <div className="inventory_details_head">
                        <Typography className="inventory_details_header" sx={{ fontWeight:600,fontFamily:'latobold',fontSize:'17px',color:"#fff"}}>MILEAGE:</Typography>
                        <Typography className="inventory_details_subheader" sx={{fontWeight:600,fontFamily:'latolight',fontSize:'16px',color:"#fff"}}> {data[20]} lmph</Typography>
                        

                    </div>
                    :
                    null
                    }
                    {data[16] ?
                    <div className="inventory_details_head">
                        <Typography className="inventory_details_header" sx={{ fontWeight:600,fontFamily:'latobold',fontSize:'17px',color:"#fff"}}>ENGINE:</Typography>
                        <Typography className="inventory_details_subheader" sx={{fontWeight:600,fontFamily:'latolight',fontSize:'16px',color:"#fff"}}>{data[16] }</Typography>
                        

                    </div>
                    :
                    null
                    }
                    {data[17] ?
                    <div className="inventory_details_head">
                        <Typography className="inventory_details_header" sx={{ fontWeight:600,fontFamily:'latobold',fontSize:'17px',color:"#fff"}}>Drive Train:</Typography>
                        <Typography className="inventory_details_subheader" sx={{fontWeight:600,fontFamily:'latolight',fontSize:'16px',color:"#fff"}}>{data[17] }</Typography>
                        

                    </div>
                    :
                    null

}
                    {data[18] ?
                    <div className="inventory_details_head">
                        <Typography className="inventory_details_header" sx={{ fontWeight:600,fontFamily:'latobold',fontSize:'17px',color:"#fff"}}>Fuel Type:</Typography>
                       
                            
                            {data[18] == 'G' ?

                            <Typography className="inventory_details_subheader" sx={{fontWeight:600,fontFamily:'latolight',fontSize:'16px',color:"#fff"}}>
                                    Gasoline Fuel
                            </Typography>
                            :
                            data[18] == 'D' ?
                            <Typography className="inventory_details_subheader" sx={{fontWeight:600,fontFamily:'latolight',fontSize:'16px',color:"#fff"}}>
                            Diesel Fuel
                            </Typography>
                            :
                            data[18] == 'D' ?
                            <Typography className="inventory_details_subheader" sx={{fontWeight:600,fontFamily:'latolight',fontSize:'16px',color:"#fff"}}>
                            Hybrid
                           </Typography>
                             :
                           data[18] == 'E' ?
                           <Typography className="inventory_details_subheader" sx={{fontWeight:600,fontFamily:'latolight',fontSize:'16px',color:"#fff"}}>
                            Electric
                           </Typography>
                             :
      
                           <Typography className="inventory_details_subheader" sx={{fontWeight:600,fontFamily:'latolight',fontSize:'16px',color:"#fff"}}>
                          Other
                           </Typography>

                            }
                        

                    </div>
                    :
                    null
                    }
                    {data[21] ?
                       <div className="inventory_details_head">
                        <Typography className="inventory_details_header" sx={{ fontWeight:600,fontFamily:'latobold',fontSize:'17px',color:"#fff"}}>Color </Typography>
                        <Typography className="inventory_details_subheader" sx={{fontWeight:600,fontFamily:'latolight',fontSize:'16px',color:"#fff"}}>{data[21] }</Typography>
                        

                    </div>
                    :
                    null
}
                </div>
                </div>

               </div>
                <div className="inventory_description">
                    <Typography sx={{fontWeight:600,fontFamily:'latobold',fontSize:'24px'}}>Description</Typography>
                    <Typography sx={{fontFamily:'latolight',fontWeight:600}}>
                   {data[28]}

                    </Typography>
                    </div>

                    <div className="additional_information">
                        <Typography className="additional_heading" sx={{fontWeight:600,fontFamily:'latobold',fontSize:'24px'}}>Additional Information</Typography>
                        <Typography mt={1}> {data[23]}</Typography>

                    </div>

                    <div className="carflix">
                <a target="_blank" href={`https://www.carfax.com/VehicleHistory/p/Report.cfx?partner=DVW_1&vin=${data[19]}`}><img src='/carfax.png' width='155' height='56' border='0' /></a>
                </div>

           </div>

             ))}
           </div>
  
  </>


    )
}


export default Details