import Listing from "./component/listing"
import ListingDetails from './component/inventoryDetail';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import RequestInfo from "./component/form"
import Script from './component/script'
function App() {
  const value = localStorage.getItem('scripts')
  return (
    <div className="App">

      <Routes>
          
    
         <Route  path= "/:id" element={<Listing />} /> 
     

      <Route path="listing-details/:id1/:id" element={<ListingDetails />} />
      <Route path="request-info/:id" element={<RequestInfo />} />

    </Routes>
    </div>
  );
}

export default App;
