import { TextField, Typography } from "@mui/material";
import React,{useState,useEffect} from "react";
import { useParams } from "react-router";
import "./form.css";

const Form = () => {

    const [value, setValue] = React.useState('');

       
    const [csv,setCsv] =  useState([])
    const [makedate]=useState(localStorage.getItem('scripts'))
    useEffect(() => {
                
        downloadCsv()
},[])


const {id} = useParams()


    function CSVToJSON(csvData) {

        var data = CSVToArray(csvData);
        var objData = [];
        for (var i = 1; i < data.length; i++) {
            objData[i - 1] = {};
            for (var k = 0; k < data[0].length && k < data[i].length; k++) {
                var key = data[0][k];
                objData[i - 1][key] = data[i][k]
            }
        }
        var jsonData = JSON.stringify(objData);
        jsonData = jsonData.replace(/},/g, "},\r\n");
        return jsonData;
    }

    function CSVToArray(csvData, delimiter) {
        delimiter = (delimiter || ",");
         var pattern = new RegExp((
        "(\\" + delimiter + "|\\r?\\n|\\r|^)" +
        "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
        "([^\"\\" + delimiter + "\\r\\n]*))"), "gi");
        var data = [[]];
        var matches = null;
        while (matches = pattern.exec(csvData)) {
            var matchedDelimiter = matches[1];
            if (matchedDelimiter.length && (matchedDelimiter != delimiter)) {
                data.push([]);
            }
            if (matches[2]) {
                var matchedDelimiter = matches[2].replace(
                new RegExp("\"\"", "g"), "\"");
            } else {
                var matchedDelimiter = matches[3];
            }
            data[data.length - 1].push(matchedDelimiter);
        }
       
       setCsv(data.splice(1))
    }
  

  

            const downloadCsv =  () => {
               fetch(`https://connect.emgsrv.com/${makedate}/${makedate}.CSV`,{
                   method:'get',
                  
                    headers:{
                        "Content-Type":"application/csv"
                    }
                })
                .then(response => 
                   response.text())
                 
                .then(data => {
                    CSVToJSON(data)
                })
            }
       


            const newdata = csv.filter((datas => datas[0] == id))
            console.log(newdata)



 
    return (
      
        <div className="request-info-container">
            <div className="cancel_return">
                <img height="20" width="20" src="/emgConnect_Return.svg" />
                <Typography ml={2} sx={{fontWeight:600,color:"#fff",fontFamily:'latobold',fontSize:'20px'}}>Cancel and Return</Typography>
            </div>
             {newdata.map(data => (
               <>
    <Typography sx={{mt:2, mb:2,fontWeight:600,fontFamily:'latobold',fontSize:'18px'}}>Request more information</Typography>

        <div className="card_list_image">
        <img src="/car.jpg" />
    </div>
    <Typography sx={{mt:2, fontWeight:600,fontFamily:'latobold',fontSize:'18px'}}>{data[10]} {data[11]} {data[12]} {data[13]}</Typography>
  
  
    <TextField 
          id="outlined-multiline-flexible"
          label="Name"
          multiline
          sx={{mt:2}}
          maxRows={4}
       
         
         
        />
          
    <TextField 
          id="outlined-multiline-flexible"
          label="Email"
          multiline
          sx={{mt:2}}
          maxRows={4}
         
         
          
        />  
        <TextField 
              id="outlined-multiline-flexible"
              label="Phone"
              multiline
              sx={{mt:2}}
              maxRows={4}
             
          

              
            />  
            <TextField 
                                id="outlined-multiline-flexible"

                  label="Type message here"
                  multiline
                  sx={{mt:2}}
                  minRows={4}
                  
                />

   <div className="button_submit">
       <Typography sx={{fontWeight:600,color:"#fff",fontFamily:'latobold'}}>Submit</Typography>
   </div>
   </>
   ))}
  
    </div>
    )
}

export default Form;