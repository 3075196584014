import React, { useEffect, useState,useRef }  from "react";
import "./listing.css";
import { Link,useParams } from "react-router-dom";
import { Carousel } from 'react-carousel-minimal';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Typography,TextField } from "@mui/material";
import CurrencyFormat from 'react-currency-format';
import { readString, usePapaParse } from 'react-papaparse';
import emailjs from '@emailjs/browser';
import { faCircleInfo, faCirclePlay, faClipboardList, faCross, faHome, faMagnifyingGlass, faMultiply } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Reaptcha from 'reaptcha';

const Listing = () => {

    const {id} = useParams()
 
    const [ids,setid] = useState()
    const [data1,setData] = useState()
    const [carouselArray,setCarouselArray] = useState()
    const [search,setSearch] = useState('');
    const [filteredData,setFilteredData] = useState()
    const [carousel,setCarousel] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [csv,setCsv] =  useState([])
    // const makedate=localStorage.getItem('scripts')csvdata[10]+ " " + csvdata[11]
    
    useEffect(() => {
                
        downloadCsv()
},[])

console.log(data1,'asasas')
const searchText = (e) => {
  
   const text =  e.target.value
   setSearch(text)

  const data =    csv.filter(csvdata => (" "+ csvdata[10]+ " " + csvdata[11] + " " + csvdata[12] + " " + csvdata[13] + " " ).includes(text.toUpperCase()) )

          setFilteredData( data)
        
}

console.log(filteredData)

console.log(search.length)
const form = useRef();


    function CSVToJSON(csvData) {

        
        var data = CSVToArray(csvData);
        
        var objData = [];
        for (var i = 1; i < data.length; i++) {
            objData[i - 1] = {};
            for (var k = 0; k < data[0].length && k < data[i].length; k++) {
                var key = data[0][k];
                objData[i - 1][key] = data[i][k]
            }
        }
        var jsonData = JSON.stringify(objData);
        
        jsonData = jsonData.replace(/},/g, "},\r\n");
        
        return jsonData;
    }


    function CSVToArray(csvData, delimiter) {
        delimiter = (delimiter || ",");
         var pattern = new RegExp((
        "(\\" + delimiter + "|\\r?\\n|\\r|^)" +
        "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
        "([^\"\\" + delimiter + "\\r\\n]*))"), "gi");
        
        var data = [[]];
        var matches = null;
        while (matches = pattern.exec(csvData)) {
            var matchedDelimiter = matches[1];

           
            if (matchedDelimiter.length && (matchedDelimiter != delimiter)) {
                data.push([]);
               

            }
            if (matches[2]) {
                var matchedDelimiter = matches[2].replace(
                new RegExp("\"\"", "g"), "\"");
                
               
            } else {
                var matchedDelimiter = matches[3];
                
            }
            data[data.length - 1].push(matchedDelimiter);
         
        }
        
       setCsv(data.splice(1))

    }
  

  

            const downloadCsv =  () => {
            //    fetch(`https://connect.emgsrv.com/D7huMcppWWRaAKMdafZW/D7huMcppWWRaAKMdafZW.CSV`,{

               fetch(`https://connect.emgsrv.com/${id}/${id}.CSV`,{
                   method:'get',
                  
                    headers:{
                        "Content-Type":"application/csv"
                    }
                })
                .then(response => 
                   response.text())
                 
                .then(data => {
                    CSVToJSON(data)
                    
                   
                    
                })
            }
       
            const [verified,setVerified] = useState(false)
            const handleOpen = () => {
              
                setOpen(true)
            }
            const handleClose = () => setOpen(false);
  
    const [openModal,setOpenModal] = React.useState(false)
    const modalOpen = () => {
              
        setOpenModal(true)
    }
    const modalClose = () => setOpenModal(false);
            const style = {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90%',
                overflow:'scroll',
                height:'100%',
                bgcolor: 'background.paper',
               
                p: 1,
              };


    const styles = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        overflow:'scroll',
        height:'100%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
     
      };
              const sendEmail = (e) => {
                e.preventDefault();
            
                emailjs.sendForm('service_ajmpcbw', 'template_7tgn2s4',form.current , '4s0sSxigPZvyRqp1P')
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });
            };
            
            const onVerify = recaptchaResponse => {
                setVerified(true)
              };
            

           const carouselOpen = (carouselId) => {
            const imageCarousel = csv.filter((datas => datas[19] == carouselId)).map(images => images[27])
         
            const imagesCarousel = imageCarousel[0].split(',').map( function(images) 
            {
                return {"image":images}
            }
            )
            
            setCarouselArray(imagesCarousel)
            setOpen(true)

           
           }
         
    return (
       <>
      
        <div className="card_list_container">
            <div className="search_inventory">
                <div style={{marginLeft:'10px'}}>
                <FontAwesomeIcon  size="lg" icon={faMagnifyingGlass} />
                </div>
                <input onChange={(e) => searchText(e)}  type="text"  placeholder="Search Inventory"/>
            </div>
            <Modal
        open={open}
        onClose={handleClose}
       
      >
        <Box sx={style}>
            <Box onClick={handleClose} sx={{float:'right',p:1,cursor:'pointer'}}>
<FontAwesomeIcon   size="lg" icon={faMultiply}   />
</Box>
<Carousel
data={carouselArray}
time={2000}
width="850px"
height="500px"

radius="10px"
slideNumber={true}
// slideNumberStyle={slideNumberStyle}
captionPosition="bottom"
automatic={true}
dots={true}
pauseIconColor="white"
pauseIconSize="40px"
slideBackgroundColor="darkgrey"
slideImageFit="cover"
thumbnails={true}
thumbnailWidth="100px"
style={{
  textAlign: "center",
  maxWidth: "850px",
  maxHeight: "500px",
  margin: "40px auto",
}}
/>
</Box>
</Modal>
               
               {search?.length > 0 ?

filteredData.length > 0 ?

filteredData.map((data) => (
        
          
            <div  className="card_list_items">
            
            
           
           
            
            {/* <Modal
        open={openModal}
        onClose={modalClose}
       
      >
        <Box sx={styles}>
        <div className="request-info-container">
            <div onClick={handleClose} className="cancel_return">
                <img height="20" width="20" src="/emgConnect_Return.svg" />
                <Typography ml={2} sx={{fontWeight:600,color:"#fff",fontFamily:'latoblack',fontSize:'20px'}}>Cancel and Return</Typography>
            </div>
            
               <>
    <Typography sx={{mt:2, mb:2,fontWeight:600,fontFamily:'latoblack',fontSize:'18px'}}>Request more information</Typography>

         <div className="card_list_image">
                    {data[27].split(',')[0] ?
                            <img width="100" src={
                                 data[27].split(',')[0]
                             } />
                             :
                             <img src="/PhotosComingSoon.jpg" />
}
                      
                </div>
    <Typography sx={{mt:2, fontWeight:600,fontFamily:'latoblack',fontSize:'18px'}}>{data[10]} {data[11]} {data[12]} {data[13]}</Typography>
    <form className="form"  ref={form} onSubmit={sendEmail}>
  
    <TextField 
          id="outlined-multiline-flexible"
          label="Name"
          fullWidth="true"
          multiline
          sx={{mt:2}}
          maxRows={4}
          name="name"
       
         
         
        />
          
    <TextField 
          id="outlined-multiline-flexible"
          label="Email"
          multiline
          sx={{mt:2}}
          fullWidth="true"

          name="email"
          maxRows={4}
         
         
          
        />  
        <TextField 
              id="outlined-multiline-flexible"
              label="Phone"
              name="phone"
              multiline
          fullWidth="true"

              sx={{mt:2}}
              maxRows={4}
             
          

              
            />  
            <TextField 
                                id="outlined-multiline-flexible"

                  label="Type message here"
                  multiline
          fullWidth="true"

                  name="message"
                  sx={{mt:2}}
                  minRows={4}
                
                  
                />
            <TextField
                type="hidden"
              id="outlined-multiline-flexible"
              label="Send to"
              name="send"
              multiline
          fullWidth="true"
             
              sx={{mt:2}}
              maxRows={4}
              defaultValue={data[7]}

            />
     <div style={{marginTop:'10px'}}>
            <Reaptcha  sitekey="6LcoFfseAAAAAGFB7-b1vK5VNScP9-mPD5nQbLzl" onVerify={onVerify} />
 </div>
 {verified == false ?
   <div  className="button_submit_dis">
      
   <button   type="submit" disabled>Submit</button>
   </div>
   :
   <div  className="button_submit">
      
   <button   type="submit" >Submit</button>
   </div>
 }
   </form>
   </>

  
    </div>
        </Box>
      </Modal>
                  */}
            
            <Link className="link" to=    {`/listing-details/${id}/${data[19]}`}>
                <Typography sx={{fontWeight:600,fontFamily:'latoblack',fontSize:'18px',color:'#333333'}}> {data[10]} {data[11]} {data[12]} {data[13]}</Typography>
            </Link>
            <div className="card_list_items_details">
                <div onClick={() => carouselOpen(data[19])} className="card_list_image">
                          {data[27] ?
                    
                            <img src={
                                 data[27].split(',')[0]
                             } />
                             :
                             <img src="/PhotosComingSoon.jpg" />
}

{data[27] ?
                             <div className="camera_icon">
                <img  src="emgConnect_Camera.svg"  />
                <div className="images_count">
          
          {data[27] ?
           data[27].split(',').length < 10 ?
          <Typography sx={{fontFamily:'latolight',fontWeight:600,color:"#fff",fontSize:'13px'}}>   

                            0{data[27].split(',').length}  
          
          </Typography>
          :
          <Typography sx={{fontFamily:'latolight',fontWeight:600,color:"#fff",fontSize:'13px'}}>   

          {data[27].split(',').length}  

        </Typography>
        :
        null
}

                 </div>

             </div>
                         :
                         null
             }    
          
                </div>
               <div className="card_list_des_container">
                <div className="card_list_description">
                <Link className="link" to=    {`/listing-details/${id}/${data[19]}`}>     
                    <Typography sx={{fontFamily:'latolight',fontWeight:600}}> {data[16]} 
                   {data[14] ? <> , V{data[14]} Cylinder Engine </> : null }
                 {data[17] ? <>   , {data[17]} </> : null }
                 {data[15] ? <>   ,{data[15]} </> : null }
                {data[21] ? <>, {data[21]} </> : null }
                {data[41] ? <>, {data[41]} </> : null}
                {data[20] ? <>, {data[20]} miles            </> : null }
                {data[9] ? <>, Stock #{data[9]} </> : null }
               </Typography>
                </Link>
                 <Typography sx={{mt:1,fontWeight:600,display:'flex',alignItems:'flex-start !important'}}>Location: <Typography sx={{ml:1,display:'flex' ,fontWeight:600,fontFamily:'latolight'}}> 
                 
                 {data[1]}{data[3] ? <>, {data[3]} </> : null } {data[2] ? <>, {data[2]} </> : null} </Typography> </Typography>
                 </div>
                 {data[27] ?
                <div className="card_list_description_img">
              
                {data[27].split(',').slice(1,3).map(images => (
                      <div onClick={() => carouselOpen(data[19])} className="card_list_image_sub">
                                  <img src= {images} />

                      </div>
                ))}
                {
              data[31] ?

             
                 <div onClick={() => carouselOpen(data[19])} className="card_list_image_sub">
                     <div className="video_thumbnail">
                   <img  src= { data[27].split(',')[0]} />
                  <div className="video_thumbail_icon">
                 <FontAwesomeIcon size="2x" icon={faCirclePlay} />
                 
                  
                      </div>
                    </div>
                    </div>
                 
                          :
                      
                          data[27] ?
                   
                          data[27].split(',').slice(4,5).map(images => (
                          <div onClick={() => carouselOpen(data[19])} className="card_list_image_sub">
                                      <img src= {images} />
      
                         </div>  
                          
                      
                      ))
                         
                          :
                          null 
                          }
                </div>
                :
                null}
                </div>
                <div className="right_container">
                <Link className="link" to=    {`/listing-details/${id}/${data[19]}`}>
                <div className="card_list_price_container">
                   <Typography sx={{fontWeight:600,fontSize:'35px',fontFamily:'latoblack',color:'#333333'}}>
                   <CurrencyFormat value={data[24]} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                   </Typography>
                </div>
                </Link>
                <div className="carflix">
                <a target="_blank" href={`https://www.carfax.com/VehicleHistory/p/Report.cfx?partner=DVW_1&vin=${data[19]}`}><img src='/carfax.png' width='155' height='56' border='0' /></a>
                </div>
                </div>
            </div>
            <div  className="bottom_button">
              
              
                <div onClick={() =>modalOpen(data[0])} className="view_details">
                <FontAwesomeIcon size='1x' icon={faCircleInfo} />
                    <Typography className="request-info-text" sx={{ml:1,fontWeight:600,fontSize:'14px',display:'flex',alignItems:'center',justifyContent:'center'}} >Request Info</Typography>
                </div>
                

                <Link className="link" to=    {`/listing-details/${id}/${data[19]}`}>
                <div className="view_details">
                <FontAwesomeIcon size='1x' icon={faClipboardList} />
                <Typography sx={{ ml:1,fontWeight:600,fontSize:'14px',display:'flex',alignItems:'center',justifyContent:'center'}} >View Details</Typography>
                </div>
                </Link>   

            </div>
            </div>
           

          
        
           
           
           ))
             :
             <h1>no search found</h1> 
:

            

            <h1>
                 {csv.map((data) => (
            <div  className="card_list_items">
            
            
           
           
            
            {/* <Modal
        open={openModal}
        onClose={modalClose}
       
      >
        <Box sx={styles}>
        <div className="request-info-container">
            <div onClick={handleClose} className="cancel_return">
                <img height="20" width="20" src="/emgConnect_Return.svg" />
                <Typography ml={2} sx={{fontWeight:600,color:"#fff",fontFamily:'latoblack',fontSize:'20px'}}>Cancel and Return</Typography>
            </div>
            
               <>
    <Typography sx={{mt:2, mb:2,fontWeight:600,fontFamily:'latoblack',fontSize:'18px'}}>Request more information</Typography>

         <div className="card_list_image">
                    {data[27].split(',')[0] ?
                            <img width="100" src={
                                 data[27].split(',')[0]
                             } />
                             :
                             <img src="/PhotosComingSoon.jpg" />
}
                      
                </div>
    <Typography sx={{mt:2, fontWeight:600,fontFamily:'latoblack',fontSize:'18px'}}>{data[10]} {data[11]} {data[12]} {data[13]}</Typography>
    <form className="form"  ref={form} onSubmit={sendEmail}>
  
    <TextField 
          id="outlined-multiline-flexible"
          label="Name"
          fullWidth="true"
          multiline
          sx={{mt:2}}
          maxRows={4}
          name="name"
       
         
         
        />
          
    <TextField 
          id="outlined-multiline-flexible"
          label="Email"
          multiline
          sx={{mt:2}}
          fullWidth="true"

          name="email"
          maxRows={4}
         
         
          
        />  
        <TextField 
              id="outlined-multiline-flexible"
              label="Phone"
              name="phone"
              multiline
          fullWidth="true"

              sx={{mt:2}}
              maxRows={4}
             
          

              
            />  
            <TextField 
                                id="outlined-multiline-flexible"

                  label="Type message here"
                  multiline
          fullWidth="true"

                  name="message"
                  sx={{mt:2}}
                  minRows={4}
                
                  
                />
            <TextField
                type="hidden"
              id="outlined-multiline-flexible"
              label="Send to"
              name="send"
              multiline
          fullWidth="true"
             
              sx={{mt:2}}
              maxRows={4}
              defaultValue={data[7]}

            />
     <div style={{marginTop:'10px'}}>
            <Reaptcha  sitekey="6LcoFfseAAAAAGFB7-b1vK5VNScP9-mPD5nQbLzl" onVerify={onVerify} />
 </div>
 {verified == false ?
   <div  className="button_submit_dis">
      
   <button   type="submit" disabled>Submit</button>
   </div>
   :
   <div  className="button_submit">
      
   <button   type="submit" >Submit</button>
   </div>
 }
   </form>
   </>

  
    </div>
        </Box>
      </Modal>
                  */}
            
            <Link className="link" to=    {`/listing-details/${id}/${data[19]}`}>
                <Typography sx={{fontWeight:600,fontFamily:'latoblack',fontSize:'18px',color:'#333333'}}> {data[10]} {data[11]} {data[12]} {data[13]}</Typography>
            </Link>
            <div className="card_list_items_details">
                <div onClick={() => carouselOpen(data[19])} className="card_list_image">
                          {data[27] ?
                    
                            <img src={
                                 data[27].split(',')[0]
                             } />
                             :
                             <img src="/PhotosComingSoon.jpg" />
}

{data[27] ?
                             <div className="camera_icon">
                <img  src="emgConnect_Camera.svg"  />
                <div className="images_count">
          
          {data[27] ?
           data[27].split(',').length < 10 ?
          <Typography sx={{fontFamily:'latolight',fontWeight:600,color:"#fff",fontSize:'13px'}}>   

                            0{data[27].split(',').length}  
          
          </Typography>
          :
          <Typography sx={{fontFamily:'latolight',fontWeight:600,color:"#fff",fontSize:'13px'}}>   

          {data[27].split(',').length}  

        </Typography>
        :
        null
}

                 </div>

             </div>
                         :
                         null
             }    
          
                </div>
               <div className="card_list_des_container">
                <div className="card_list_description">
                <Link className="link" to=    {`/listing-details/${id}/${data[19]}`}>     
                    <Typography sx={{fontFamily:'latolight',fontWeight:600}}> {data[16]} 
                   {data[14] ? <> , V{data[14]} Cylinder Engine </> : null }
                 {data[17] ? <>   , {data[17]} </> : null }
                 {data[15] ? <>   ,{data[15]} </> : null }
                {data[21] ? <>, {data[21]} </> : null }
                {data[41] ? <>, {data[41]} </> : null}
                {data[20] ? <>, {data[20]} miles            </> : null }
                {data[9] ? <>, Stock #{data[9]} </> : null }
               </Typography>
                </Link>
                 <Typography sx={{mt:1,fontWeight:600,display:'flex',alignItems:'flex-start !important'}}>Location: <Typography sx={{ml:1,display:'flex' ,fontWeight:600,fontFamily:'latolight'}}> 
                 
                 {data[1]}{data[3] ? <>, {data[3]} </> : null } {data[2] ? <>, {data[2]} </> : null} </Typography> </Typography>
                 </div>
                 {data[27] ?
                <div className="card_list_description_img">
              
                {data[27].split(',').slice(1,3).map(images => (
                      <div onClick={() => carouselOpen(data[19])} className="card_list_image_sub">
                                  <img src= {images} />

                      </div>
                ))}
                {
              data[31] ?

             
                 <div onClick={() => carouselOpen(data[19])} className="card_list_image_sub">
                     <div className="video_thumbnail">
                   <img  src= { data[27].split(',')[0]} />
                  <div className="video_thumbail_icon">
                 <FontAwesomeIcon size="2x" icon={faCirclePlay} />
                 
                  
                      </div>
                    </div>
                    </div>
                 
                          :
                      
                          data[27] ?
                   
                          data[27].split(',').slice(4,5).map(images => (
                          <div onClick={() => carouselOpen(data[19])} className="card_list_image_sub">
                                      <img src= {images} />
      
                         </div>  
                          
                      
                      ))
                         
                          :
                          null 
                          }
                </div>
                :
                null}
                </div>
                <div className="right_container">
                <Link className="link" to=    {`/listing-details/${id}/${data[19]}`}>
                <div className="card_list_price_container">
                   <Typography sx={{fontWeight:600,fontSize:'35px',fontFamily:'latoblack',color:'#333333'}}>
                   <CurrencyFormat value={data[24]} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                   </Typography>
                </div>
                </Link>
                <div className="carflix">
                <a target="_blank" href={`https://www.carfax.com/VehicleHistory/p/Report.cfx?partner=DVW_1&vin=${data[19]}`}><img src='/carfax.png' width='155' height='56' border='0' /></a>
                </div>
                </div>
            </div>
            <div  className="bottom_button">
              
              
                <div onClick={() =>modalOpen(data[0])} className="view_details">
                <FontAwesomeIcon size='1x' icon={faCircleInfo} />
                    <Typography className="request-info-text" sx={{ml:1,fontWeight:600,fontSize:'14px',display:'flex',alignItems:'center',justifyContent:'center'}} >Request Info</Typography>
                </div>
                

                <Link className="link" to=    {`/listing-details/${id}/${data[19]}`}>
                <div className="view_details">
                <FontAwesomeIcon size='1x' icon={faClipboardList} />
                <Typography sx={{ ml:1,fontWeight:600,fontSize:'14px',display:'flex',alignItems:'center',justifyContent:'center'}} >View Details</Typography>
                </div>
                </Link>   

            </div>
            </div>
            ))}
            </h1>
    }
            
        </div>



        {/* mobile screen */}

        <div className="mobile_screen_display">
        <div className="search_inventory">
                <div style={{marginLeft:'10px'}}>
                <FontAwesomeIcon  size="lg" icon={faMagnifyingGlass} />
                </div>
                <input  type="text"  placeholder="Search Inventory"/>
            </div>
        {csv.map((data,index) => (
            
            <div className="mobile_card_list_items">
                {/* <Modal
        open={open}
        onClose={handleClose}
       
      >
        <Box sx={style}>
        <div className="request-info-container">
            <div onClick={handleClose} className="cancel_return">
                <img height="20" width="20" src="/emgConnect_Return.svg" />
                <Typography ml={2} sx={{fontWeight:600,color:"#fff",fontFamily:'latoblack',fontSize:'20px'}}>Cancel and Return</Typography>
            </div>
          
               <>
    <Typography sx={{mt:2, mb:2,fontWeight:600,fontFamily:'latoblack',fontSize:'18px'}}>Request more information</Typography>

        <div className="card_list_image">
        <img src="/car.jpg" />
    </div>
    <Typography sx={{mt:2, fontWeight:600,fontFamily:'latoblack',fontSize:'18px'}}>{data[10]} {data[11]} {data[12]} {data[13]}</Typography>
    <form className="form"  ref={form} onSubmit={sendEmail}>
  
    <TextField 
          id="outlined-multiline-flexible"
          label="Name"
          fullWidth="true"
          multiline
          sx={{mt:2}}
          maxRows={4}
          name="name"
       
         
         
        />
          
    <TextField 
          id="outlined-multiline-flexible"
          label="Email"
          multiline
          sx={{mt:2}}
          fullWidth="true"

          name="email"
          maxRows={4}
         
         
          
        />  
        <TextField 
              id="outlined-multiline-flexible"
              label="Phone"
              name="phone"
              multiline
          fullWidth="true"

              sx={{mt:2}}
              maxRows={4}
             
          

              
            />  
            <TextField 
                                id="outlined-multiline-flexible"

                  label="Type message here"
                  multiline
          fullWidth="true"

                  name="message"
                  sx={{mt:2}}
                  minRows={4}
                
                  
                />
            <TextField
            disabled
              id="outlined-multiline-flexible"
              label="Send to"
              name="send"
              multiline
          fullWidth="true"

              sx={{mt:2}}
              maxRows={4}
              defaultValue={data[7]}

            />
     <div style={{marginTop:'10px'}}>
            <Reaptcha  sitekey="6LcoFfseAAAAAGFB7-b1vK5VNScP9-mPD5nQbLzl" onVerify={onVerify} />
 </div>
 {verified == false ?
   <div  className="button_submit_dis">
      
   <button   type="submit" disabled>Submit</button>
   </div>
   :
   <div style={{background:'red !important'}} className="button_submit">
      
   <button  style={{background:'red !important'}} type="submit" disabled={!verified}>Submit</button>
   </div>
 }
   </form>
   </>
   
  
    </div>
        </Box>
      </Modal> */}

                <div onClick={() => carouselOpen(data[19])} className="mobile_card_image">
                {data[27] ?
                            <img src={
                                 data[27].split(',')[0]
                             } />
                             :
                             <img src="/PhotosComingSoon.jpg" />
                    
}
<div className="camera_icon">
                <img  src="emgConnect_Camera.svg"  />
                <div className="images_count">
          
          {data[27] ?
          data[27].split(',').length < 10 ?
          <Typography sx={{fontFamily:'latolight',fontWeight:600,color:"#fff",fontSize:'13px'}}>   

                            0{data[27].split(',').length}  
          
          </Typography>
          :
          <Typography sx={{fontFamily:'latolight',fontWeight:600,color:"#fff",fontSize:'13px'}}>   

          {data[27].split(',').length}  

        </Typography>
        :
        null
}
                 </div>
                
             </div>
          
                </div>
            
                <div className="card_list_description_img">
               {data[27] ?
              data[27].split(',').slice(1,3).map(images => (
                    <div onClick={() => carouselOpen(data[19])} className="card_list_image_sub">
                                <img src= {images} />

                    </div>
              ))
              :
              null
              }
              {
            data[31] ?

           
               <div onClick={() => carouselOpen(data[19])} className="card_list_image_sub">
                   <div className="video_thumbnail">
                 <img  src= { data[27].split(',')[0]} />
                 <div className="video_thumbail_icon">
                 <FontAwesomeIcon size="2x" icon={faCirclePlay} />
                 
                  
                      </div>
                  </div>
                  </div>
               
                :
                    data[27] ?
                   
                    data[27].split(',').slice(4,5).map(images => (
                    <div onClick={() => carouselOpen(data[19])} className="card_list_image_sub">
                                <img src= {images} />

                   </div>  
                    
                
                ))
                   
                    :
                    null   
         
                        }
              </div>
             
                <Link className="link" to=    {`/listing-details/${id}/${data[19]}`}>
                <div className="">
                <Typography sx={{fontWeight:700,fontFamily:'latoblack',fontSize:'18px',mt:2}}>{data[10]} {data[11]} {data[12]} {data[13]}</Typography>
                </div>
                </Link>
                <Link className="link" to=    {`/listing-details/${id}/${data[19]}`}>
                <div className="card_list_price_container">
                   <Typography sx={{fontWeight:900,fontSize:'35px',fontFamily:'latoblack'}}>
                   <CurrencyFormat value={data[24]} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                   </Typography>
                </div>
                </Link>
                <div className="card_list_description">
                <Link className="link" to=    {`/listing-details/${id}/${data[19]}`}>
                <Typography sx={{fontFamily:'latolight',fontWeight:600}}> {data[16]} 
                   {data[14] ? <> , V{data[14]} Cylinder Engine </> : null }
                 {data[17] ? <>   , {data[17]} </> : null }
                 {data[15] ? <>   ,{data[15]} </> : null }
                {data[21] ? <>, {data[21]} </> : null }
                {data[41] ? <>, {data[41]} </> : null}
                {data[20] ? <>      , {data[20]} miles           </> : null }
               {data[9] ? <> ,Stock #{data[9]} </> : null} 
               </Typography>
                </Link>
                 <Typography sx={{mt:1,fontWeight:600,display:'flex',alignItems:'flex-start !important'}}>Location: <Typography sx={{ml:1,fontWeight:600,fontFamily:'latolight'}}></Typography> {data[1]}{data[3] ? <>, {data[3]} </> : null } {data[2] ? <>, {data[2]} </> : null}</Typography>
      
            </div>
            <div  className="bottom_button">
              
              <Link className="link" to={`/request-info/${data[19]}`}>
              <div className="view_details">
              <FontAwesomeIcon size='1x' icon={faCircleInfo} />
                  <Typography className="request-info-text" sx={{ml:0.8,mt:0.1,fontWeight:600,fontSize:'14px',display:'flex',alignItems:'center',justifyContent:'center'}} >Request Info</Typography>
              </div>
              </Link>

              <Link className="link" to=    {`/listing-details/${id}/${data[19]}`}>
              <div className="view_details">
              <FontAwesomeIcon size='1x' icon={faClipboardList} />
              <Typography sx={{ml:0.5,mt:0.1, fontWeight:600,fontSize:'14px',display:'flex',alignItems:'center',justifyContent:'center'}} >View Details</Typography>
              </div>
              </Link>   

          </div>
            </div>
        ))}
        </div>


</>
      
    )
}

export default Listing